import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import useLinks from "../hooks/useLinks"
import useViewport from "../hooks/useViewport"
import device, { sizes } from "../device"
import { Logo } from "../hero/hero-image"
import login from "../../images/header/login.svg"
import Ratings from "../ratings/widget"
import HeaderLinks from "./header-links"

const Wrapper = styled.div`
  transition: all 0.3s ease-out;
  &:hover {
    background: var(--white);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    ${device.medium`background: transparent;`}
    .logo {
      filter: var(--black-filter);
      ${device.medium`filter: none;`}
    }
  }
  .container {
    position: relative; // for dropdown
    width: 90%;
    max-width: var(--limit);
    margin: 0 auto;
    display: flex;
    align-items: center;
    ${device.medium`width: 90%; padding: 0.5rem 0; z-index: 1000;`}
    .header-links-wrapper {
      ${device.medium`display: none;`}
    }
  }

  .links {
    flex: 1;
    display: flex;
    align-items: center;
    .logo-wrapper {
      margin-right: 3rem;
      ${device.medium`margin-right: 0;`}
      .logo {
        width: 130px;
        ${device.medium`width: 100px;`}
        ${device.large`width: 150px;`}
      }
    }
  }
  .login {
    display: none;
    ${device.medium`display: flex; align-items: center; font-size: 0.9rem;`}
    img {
      margin-right: 0.5rem;
      width: 20px;
    }
  }

  .ratings-wrapper {
    padding: 0.5rem 0;
    ${device.medium`display: none;`}
  }
`

const SecondaryHeader = () => {
  const [isActive, setActive] = useState(false)
  const isTablet = useViewport() <= sizes.medium
  const extLinks = useLinks()

  return (
    <Wrapper
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <div className="container">
        <div className="links">
          <Link className="logo-wrapper" to="/">
            <Logo className="logo" />
          </Link>
          <div className="header-links-wrapper">
            {!isTablet && <HeaderLinks isActive={isActive} />}
          </div>
        </div>

        <div className="ratings-wrapper">{!isTablet && <Ratings />}</div>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={extLinks.login}
          className="login"
        >
          <img src={login} alt="Login link" />
          <span>Login</span>
        </a>
      </div>
    </Wrapper>
  )
}

export default SecondaryHeader
