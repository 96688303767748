import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { sizes } from "../device"

const useImages = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopBg: file(relativePath: { eq: "hero/bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobileBg: file(relativePath: { eq: "hero/bg-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      leftCar: file(relativePath: { eq: "hero/left-car.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      leftCarMobile: file(relativePath: { eq: "hero/left-car.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rightCar: file(relativePath: { eq: "hero/right-car.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rightCarMobile: file(relativePath: { eq: "hero/right-car.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      road: file(relativePath: { eq: "hero/road.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      logo: file(relativePath: { eq: "footer/logo footer.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return data
}

const Bg = ({ data }) => {
  const sources = [
    data.mobile.localFile.childImageSharp.fluid,
    {
      ...data.desktop.localFile.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]
  return (
    <Img
      fluid={sources}
      style={{ width: "100%", height: "100%" }}
      alt="Hero section background"
    />
  )
}

const LeftCar = () => {
  const data = useImages()
  const sources = [
    data.leftCarMobile.childImageSharp.fluid,
    {
      ...data.leftCar.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]
  return <Img fluid={sources} fadeIn={false} alt="Car fading in from left" />
}

const RightCar = () => {
  const data = useImages()
  const sources = [
    data.rightCarMobile.childImageSharp.fluid,
    {
      ...data.rightCar.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]
  return <Img fluid={sources} fadeIn={false} alt="Car fading in from right" />
}

const Road = () => {
  const data = useImages()
  return (
    <Img
      fluid={data.road.childImageSharp.fluid}
      alt="Road"
      objectPosition="top"
      objectFit="cover"
      style={{ width: "100%", height: "100%" }}
    />
  )
}

const Logo = ({ className }) => {
  const data = useImages()
  return (
    <Img
      fluid={data.logo.childImageSharp.fluid}
      className={className}
      loading="eager"
      fadeIn={false}
      alt="King Transportation logo"
    />
  )
}

export { Bg, LeftCar, RightCar, Road, Logo }
