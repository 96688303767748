import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const ReviewsContainer = styled.div`
  background-color: #a5a86c;
  color: #fff;
  width: 145px;
  height: 75px;
  display: flex;
  flex-direction: row;
  padding: 12px 8px 9px 11px;
  border-radius: 2px;
  box-shadow: 5px 5px 8px -4px rgba(0, 0, 0, 0.3);
  font-family: sans-serif;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
  box-sizing: border-box;
  text-transform: none;
`
const TextAndStarsContainer = styled.div``

const ImageContainer = styled.div`
  margin-left: 10px;
`

const StarsAvgRatingContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
`

const SvgContainer = styled.div`
  font-size: 11px;
`

const AvgRating = styled.div`
  margin-left: 5px;
  font-weight: 600;
  font-size: 11px;
`

const ReviewsWidget = ({}) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      query1: allBirdeyedata(
        filter: { id: { ne: "dummy" }, sourceType: { ne: "Direct Feedback" } }
      ) {
        nodes {
          rating
        }
      }
      query2: allBirdeyedata(
        filter: { id: { ne: "dummy" }, rating: { ne: 0 } }
      ) {
        nodes {
          rating
        }
      }
      file(name: { eq: "whitelabel-icon" }) {
        childImageSharp {
          fixed(height: 43, width: 43) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  function length(obj) {
    return Object.keys(obj).length
  }
  const totalRatings = length(data.query1.nodes)
  const fiveFourThreeRatings = length(data.query2.nodes)

  let total = 0
  const ratingsTotal = data.query1.nodes
  let i
  for (i = 0; i < ratingsTotal.length; i++) {
    total += ratingsTotal[i].rating
  }

  let averageRating = total / fiveFourThreeRatings
  averageRating = averageRating.toFixed(1)

  let rectWidth = averageRating * 20 + "%"

  return (
    <ReviewsContainer>
      <TextAndStarsContainer>
        {totalRatings}
        <br />
        Client
        <br />
        Reviews
        <StarsAvgRatingContainer>
          <SvgContainer>
            <svg viewBox="0 0 1000 200" class="rating">
              <defs>
                <polygon
                  id="star"
                  points="100,0 131,66 200,76 150,128 162,200 100,166 38,200 50,128 0,76 69,66 "
                />

                <clipPath id="stars">
                  <use xlinkHref="#star" />
                  <use xlinkHref="#star" x="20%" />
                  <use xlinkHref="#star" x="40%" />
                  <use xlinkHref="#star" x="60%" />
                  <use xlinkHref="#star" x="80%" />
                </clipPath>
              </defs>

              <rect
                style={{ fill: "#dddddd", height: "100%", width: "100%" }}
                clip-path="url(#stars)"
              ></rect>

              <rect
                width={rectWidth}
                style={{ fill: "#FFB300", height: "100%" }}
                clip-path="url(#stars)"
              ></rect>
            </svg>
          </SvgContainer>
          <AvgRating>{averageRating}</AvgRating>
        </StarsAvgRatingContainer>
      </TextAndStarsContainer>
      <ImageContainer>
        <Img fixed={data.file.childImageSharp.fixed} />
      </ImageContainer>
    </ReviewsContainer>
  )
}
export default ReviewsWidget
