import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import GreenHeading from "../components/heading"
import SEO from "../components/seo"
import Layout from "../components/layout"
import SecondaryHeader from "../components/header/header-secondary"
import device from "../components/device"

const Wrapper = styled.section`
  .secondary-header-wrapper {
    background: var(--darkgrey);
    color: var(--white);
  }
  .content {
    max-width: 1200px;
    width: 70%;
    margin: 5rem auto 10rem auto;
    ${device.small`width: var(--spread);`}
    .heading-wrapper {
      .heading {
        line-height: 1.2;
        font-size: 3rem;
        ${device.small`display: block; line-height: 1; font-size: 2.5rem;`}
        .secondary-heading {
          margin-left: 3rem;
          ${device.small`margin-left: 2rem; font-size: 2rem;`}
        }
      }
    }
  }
  .faqs-section {
    margin-top: 4rem;
    .item {
      margin: 2rem 0;
      .question {
        font-weight: 700;
        font-size: 1.1rem;
      }

      .answer {
        margin: 0.5rem 0;
        font-size: 0.9rem;
      }
    }
  }
`

const FaqPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={`${data.site.siteMetadata.title} | FAQ`} />
      <Wrapper>
        <div className="secondary-header-wrapper">
          <SecondaryHeader />
        </div>
        <div className="content">
          <div className="heading-wrapper">
            <h1 className="heading">
              <GreenHeading size="6rem" text="Frequently" />
              <br />
              <span className="secondary-heading">asked questions</span>
            </h1>
          </div>
          <section className="faqs-section">
            {data.allWordpressWpFaqs.edges.map(({ node }, index) => (
              <div key={index} className="item">
                <h4 className="question">{node.title}</h4>
                <div
                  className="answer"
                  dangerouslySetInnerHTML={{
                    __html: node.content,
                  }}
                />
              </div>
            ))}
          </section>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
  {
    allWordpressWpFaqs {
      edges {
        node {
          title
          content
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
