import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import downArrow from "../../images/header/down-arrow.svg"
import device from "../device"

const Navbar = styled.nav`
  display: flex;
  flex-wrap: wrap;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  .primary-link {
    cursor: pointer;
    margin: 1rem 3rem 1rem 0;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      & > a {
        color: var(--green);
      }
    }
    a {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      color: ${props => (props.isActive ? "var(--black)" : "var(--white)")};
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
`

const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 70%;
  max-width: 1000px;
  height: 280px;
  background: var(--green);
  z-index: 500;
  display: flex;
  ${device.medium`width: 100%;`}
  ${device.large`height: 350px;`}
  .box {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    border: 1px solid var(--white);
    padding: 1rem;
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--black);
      border-radius: 10px;
      padding: 0.5rem;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--white);
      border-radius: 10px;
    }
  }
  .secondary-links {
    .link {
      width: 100%;
      display: flex;
      align-items: center;
      &.multi {
        max-width: 100px;
      }
      a {
        flex: 1;
        color: var(--white);
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
      }
      .arrow {
        width: 10px;
        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .sub-link-wrapper.active {
      height: auto;
      max-height: 400px;
    }

    .sub-link-wrapper {
      transition: all 0.5s;
      margin-bottom: 1rem;
      height: 0;
      max-height: 0;
      overflow: hidden;
      ${device.large`margin-bottom: 1.5rem;`}
      .sub-link {
        margin: 0.5rem;
        ${device.large`margin: 1rem;`}
        a {
          color: var(--white);
          font-family: "Montserrat", sans-serif;
        }
      }
    }
  }
  .description {
    h3 {
      font-size: 1.2rem;
      ${device.large`font-size: 1.5rem;`}
    }
    p {
      line-height: 1.8;
      margin: 0.5rem 0;
      font-size: 0.6rem;
      ${device.large`font-size: 0.7rem;`}
    }
  }
`

const DropDown = ({ item, content }) => {
  const [isSubActive, setSubActive] = useState(null)

  function handleClick(e) {
    const currIndex = e.currentTarget.dataset.index
    isSubActive === currIndex ? setSubActive(null) : setSubActive(currIndex)
  }

  return (
    <>
    {content &&
    <Container>
      
      <div className="image box">
        <Img
          fluid={content.image.localFile.childImageSharp.fluid}
          alt="Link thumbnail"
          style={{ width: "100%", height: "100%" }}
        />
      </div>

      <div className="secondary-links box">
        <div className="inner">
          {item.childItems.nodes.map((sub_item, i) => (
            <div className="link-wrapper" key={i}>
              <div
                className={`link ${sub_item.childItems.nodes.length !==0 && "multi"}`}
                key={i}
              >
                <Link to={sub_item.path}>
                  {sub_item.label}
                </Link>
                {sub_item.childItems.nodes.length !==0 && (
                  <img
                    src={downArrow}
                    alt="down arrow"
                    onClick={handleClick}
                    data-index={i}
                    className={`arrow ${parseInt(isSubActive) === i &&
                      "active"}`}
                  />
                )}
              </div>

              <div
                className={`sub-link-wrapper ${parseInt(isSubActive) === i &&
                  "active"}`}
              >
                {sub_item.childItems.nodes.length !==0 &&
                  sub_item.childItems.nodes.map((sub_sub_item, j) => (
                    <div className="sub-link" key={j}>
                      <Link
                        to={sub_sub_item.path}
                        key={j}
                      >
                        {sub_sub_item.label}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="description box">
        <div className="inner">
          <h3>{item.label}</h3>
          
          <p>{content.text}</p>

        </div>
      </div>
    </Container>
}
</>
  )
}

const HeaderLinks = ({ isActive }) => {
  const data = useStaticQuery(graphql`
  {
    wpgraphql {
      menuItems(where: {location: PRIMARY, parentDatabaseId: 0}) {
        nodes {
          label
          path
          childItems {
            nodes {
              label
              path
              childItems {
                nodes {
                  label
                  path
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        fleet {
          text
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        our_services {
          text
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        why_king {
          text
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
  `)

  const content = [
    data.wordpressAcfOptions.options.our_services,
    data.wordpressAcfOptions.options.fleet,
    data.wordpressAcfOptions.options.why_king,
  ]

  const [isDropdownActive, setDropdownActive] = useState(null)

  function handleHover(e) {
    setDropdownActive(e.currentTarget.dataset.index)
  }

  useEffect(() => {
    !isActive && setDropdownActive(null)
  }, [isActive])

  return (
    <Navbar isActive={isActive}>
      {data.wpgraphql.menuItems.nodes.map((item, index) => (
        <div
          className={`primary-link ${
            parseInt(isDropdownActive) === index ? "active" : ""
          }`}
          key={index}
          data-index={index}
          onMouseEnter={handleHover}
        >
          <Link to={item.path}>{item.label}</Link>
          {item.childItems.nodes.length !==0 && parseInt(isDropdownActive) === index && (
            <DropDown item={item} content={content[index]} />
          )}
        </div>
      ))}
    </Navbar>
  )
}

export default HeaderLinks
