import React, { useEffect } from "react"
import { Link } from 'gatsby'
import styled from "styled-components"
import ReviewsWidget from "../../components/ReviewsWidget"

const Box = styled.div`
  #bf-revz-widget-123456800686 {
    width: 135px;
    height: 76px;
    iframe {
      -ms-zoom: 0.5;
      -moz-transform: scale(0.5);
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.5);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.5);
      -webkit-transform-origin: 0 0;
    }
  }
`

const Ratings = () => {
  

  return (
    <Box>
      <Link to="/why-king/reviews/">
      <ReviewsWidget />
      </Link>
    </Box>
  )
}

export default Ratings
